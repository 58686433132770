
import { OnlineCourses } from "@/api";
import { LearningMaterial } from "@/api/types";
import { questionType } from "@/utils/constants";
import { formatGrade, transformAnswers } from "@/utils/helpers";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Component, Prop, Vue } from "vue-property-decorator";
import { deleteConfirmation } from "../../utils/helpers";
import LearningMaterialBlock from "./LearningMaterial.vue";

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    LearningMaterialBlock
  }
})
export default class LearningMaterials extends Vue {
  @Prop()
  courseId!: number;
  @Prop()
  maxGrade!: number;
  @Prop()
  isUSQualificationType!: boolean;
  @Prop({ default: false })
  blocked!: boolean;

  loading = false;

  formativeMaxGrade = 0;
  initialLearningMaterial: LearningMaterial = {
    video: "",
    name: "",
    description: "",
    maxGrade: 0,
    questions: [],
    deleteQuestions: []
  };
  learningMaterials: LearningMaterial[] = [
    { ...JSON.parse(JSON.stringify(this.initialLearningMaterial)) }
  ];
  formatGrade = formatGrade;
  deleteConfirmation = deleteConfirmation;

  async mounted() {
    try {
      this.loading = true;
      const tests = await OnlineCourses.getFormativeTests(this.courseId);

      if (tests && tests.length) {
        this.learningMaterials = tests.map((block: any) => {
          const questions = (block?.questions || ([] as any[])).map(
            (question: any) => {
              if (question.type?.name !== "Open") {
                const answers = transformAnswers(question);
                return { ...question, answers };
              }
              return { ...question };
            }
          );

          return { ...block, questions, deleteQuestions: [] };
        });
        this.formativeMaxGrade = this.maxGrade ? this.maxGrade : 0;
      }
    } catch (error) {
      // console.log("error: ", error);
    } finally {
      this.loading = false;
    }
  }

  addLearningBlock() {
    this.learningMaterials.push({
      ...JSON.parse(JSON.stringify(this.initialLearningMaterial))
    });
  }

  async save() {
    if (this.formativeMaxGrade < 1) {
      this.$error({ error: `Formative Max Grade must more then 0` });
      return;
    }
    this.loading = true;

    const blocks = this.$refs.block as Vue & any;

    const blocksGrade = this.learningMaterials.reduce((acc, block) => {
      return acc + +block.maxGrade;
    }, 0);

    if (blocksGrade != +this.formativeMaxGrade) {
      this.$error({
        error: `Total blocks max grade must be equal ${this.formativeMaxGrade}`
      });
      this.loading = false;
      return;
    }

    for (let i = 0; i < blocks.length; i++) {
      const valid = await blocks[i].validateBlock();
      if (!valid) {
        this.$error({ error: `Block ${i + 1} is invalid.` });
        this.loading = false;
        return;
      }
    }

    let isGradeValid = true;

    this.learningMaterials.forEach((material, ind) => {
      const totalInBlock = material.questions.reduce(
        (acc: number, cur: any) => {
          return acc + +cur.grade;
        },
        0
      );

      if (totalInBlock != material.maxGrade) {
        this.$error({
          block: `Block ${ind + 1} is invalid.<br/>Total grade must be equal ${
            material.maxGrade
          }`
        });
        this.loading = false;
        isGradeValid = false;
        return;
      }
    });
    if (!isGradeValid) return;

    try {
      const datas = this.learningMaterials.map(block => {
        const questions = block.questions.map(question => {
          const answers: string[] = [];
          const variants: string[] = [];

          if (question.type?.name !== "Open") {
            question.answers.forEach((answer: any) => {
              variants.push(answer.answer);
              if (question.type.name === questionType.correspondence.name) {
                if (answer.correct && answer.correct !== "-") {
                  answers.push(`${answer.correct}${answer.answer}`);
                }
              } else {
                if (answer.correct) {
                  answers.push(answer.answer);
                }
              }
            });
          }

          const maxAnswers =
            question.type?.name === "Open" ? 1 : answers.length;

          return {
            ...question,
            maxAnswers,
            variants,
            answers
          };
        });

        return {
          ...block,
          onlineCourseId: this.courseId,
          questions
        };
      });

      const formData = new FormData();
      formData.append("formative_max_grade", String(this.formativeMaxGrade));

      for (let i = 0; i < datas.length; i++) {
        if (datas[i].video && typeof datas[i].video !== "string") {
          formData.append(`blocks[${i}][video]`, datas[i].video);
        }
        if (datas[i].id) {
          formData.append(`blocks[${i}][id]`, String(datas[i].id));
        }

        formData.append(`blocks[${i}][description]`, datas[i].description);
        formData.append(`blocks[${i}][name]`, datas[i].name);
        formData.append(`blocks[${i}][max_grade]`, "" + datas[i].maxGrade);

        if (datas[i].deleteQuestions) {
          datas[i].deleteQuestions.forEach((question: any, ind: number) => {
            formData.append(`blocks[${i}][delete_questions][${ind}]`, question);
          });
        }

        datas[i].questions.forEach((question: any, ind: number) => {
          formData.append(
            `blocks[${i}][questions][${ind}][text]`,
            question.text
          );
          formData.append(
            `blocks[${i}][questions][${ind}][grade]`,
            question.grade
          );
          formData.append(
            `blocks[${i}][questions][${ind}][note]`,
            question.note || ""
          );
          formData.append(
            `blocks[${i}][questions][${ind}][type_id]`,
            question.type.id
          );
          formData.append(
            `blocks[${i}][questions][${ind}][max_answers]`,
            question.maxAnswers
          );

          if (question.questionId) {
            formData.append(
              `blocks[${i}][questions][${ind}][id]`,
              question.questionId
            );
          }
          if (question.type?.name !== "Open") {
            question.variants.forEach((variant: string, aaind: number) => {
              formData.append(
                `blocks[${i}][questions][${ind}][variants][${aaind}]`,
                variant
              );
            });

            question.answers.forEach((answer: any, vind: number) => {
              formData.append(
                `blocks[${i}][questions][${ind}][answers][${vind}]`,
                answer
              );
            });
          } else {
            formData.append(
              `blocks[${i}][questions][${ind}][variants][0]`,
              "-"
            );
            formData.append(`blocks[${i}][questions][${ind}][answers][0]`, "-");
          }

          if (question.files) {
            question.files.forEach((file: any, find: number) => {
              if (datas[i].id) {
                if (file.originalName) {
                  formData.append(
                    `blocks[${i}][questions][${ind}][files][${find}][original_name]`,
                    file.originalName
                  );
                } else {
                  formData.append(
                    `blocks[${i}][questions][${ind}][files][${find}]`,
                    file
                  );
                }
              } else {
                formData.append(
                  `blocks[${i}][questions][${ind}][files][${find}]`,
                  file
                );
              }
            });
          }
        });
      }

      await OnlineCourses.storeFormativeTest(this.courseId, formData);

      this.$success(
        "<strong>Success!</strong> Formative Assessments have been saved!"
      );

      this.$emit("saved");
    } catch (e) {
      const err = e as any;
      await this.$error(err.errors || { err: err.result.message });
    } finally {
      this.loading = false;
    }
  }

  async removeBlock(ind: number, item: LearningMaterial) {
    const res = await this.deleteConfirmation(
      "Do you really want to delete block?"
    );

    if (res === "Yes") {
      try {
        if (item.id) {
          await OnlineCourses.deleteBlockById(item.id);
        }
        if (this.learningMaterials.length > 1) {
          this.learningMaterials = this.learningMaterials.filter(
            (_, index) => index != ind
          );
        } else {
          this.learningMaterials = [
            { ...JSON.parse(JSON.stringify(this.initialLearningMaterial)) }
          ];
        }
        this.$success(
          `<strong>Success!</strong> Block ${item.name} has been deleted!`
        );
      } catch (e) {
        const err = e as any;
        await this.$error(err.errors || { err: err.result.message });
      }
    }
  }

  async deleteTest() {
    const res = await this.deleteConfirmation(
      "Do you really want to delete Formative Assessment?"
    );
    if (res === "Yes") {
      try {
        await OnlineCourses.deleteFormativeTest(this.courseId);
        this.$success(
          `<strong>Success!</strong> Formative Assessment has been deleted!`
        );
        this.$emit("deleteFormativeTest");
      } catch (e) {
        const err = e as any;
        await this.$error(err.errors || { err: err.result.message });
      }
    }
  }
}
