
import { OnlineCourses } from "@/api";
import CorrespondenceQuestion from "@/components/onlineCourses/CorrespondenceQuestion.vue";
import OpenQuestion from "@/components/onlineCourses/OpenQuestion.vue";
import SingleQuestion from "@/components/onlineCourses/SingleQuestion.vue";
import { EQuestionType } from "@/store/question.types";
import { toBase64 } from "@/utils/helpers";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { questionType } from "../../utils/constants";

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    SingleQuestion,
    CorrespondenceQuestion,
    OpenQuestion
  }
})
export default class LearningMaterial extends Vue {
  @Prop({ default: false })
  blocked!: boolean;
  @Prop({ required: true })
  learningMaterial!: {
    description: string;
    id: number;
    name: string;
    maxGrade: number;
    onlineCourseId: number;
    questions: {
      questionId: number;
      text: string;
      grade: number;
      type: EQuestionType;
      note: string;
      answers: any[];
      files: any;
      links: { originalName: string; url: string }[];
    }[];
    video: string;
    deleteQuestions: number[];
  };

  videoSrc = "";
  localVideo = false;
  deletingVideo = false;
  videoInViewport = true;

  initialAnswer: { answer: string; correct: boolean } = {
    answer: "",
    correct: false
  };
  initialSingleQuestion = {
    text: "",
    grade: 0,
    note: "",
    answers: [
      { answer: "", correct: true },
      { answer: "", correct: false }
    ],
    files: null
  };
  initialOpenQuestion = {
    text: "",
    grade: 0,
    note: ""
  };
  initialCorrespondenceQuestion = {
    text: "",
    grade: 0,
    note: "",
    answers: [
      { answer: "", correct: "-" },
      { answer: "", correct: "-" }
    ],
    files: null
  };

  @Watch("learningMaterial", { immediate: true })
  onMounted() {
    if (this.learningMaterial.video) {
      this.videoSrc = this.learningMaterial.video;
    }
    this.learningMaterial.questions.forEach(question => {
      if (question.files && question.files.length) {
        question.links = [...question.files];
        question.files = [];
      }
    });
  }

  handleVideoChoose() {
    if (this.blocked) return;
    const video: any = this.$refs.videoInput;
    video.click();
  }

  // scrollHandler(e: any) {
  //   this.videoElementHandler();
  // }
  // videoElementHandler() {
  //   const element = this.$refs[`video-${this.learningMaterial.id}`] as any;
  //   if (element) {
  //     const rect = element.getBoundingClientRect();
  //     // console.log("e ", this.learningMaterial.id, ": ", rect);

  //     if (rect.bottom < 0 || rect.top > window.innerHeight) {
  //       this.videoInViewport = false;
  //     } else {
  //       this.videoInViewport = true;
  //     }
  //   }
  // }

  // mounted() {
  //   this.videoElementHandler();
  //   window.addEventListener("scroll", this.scrollHandler);
  // }
  // beforeDestroy() {
  //   window.removeEventListener("scroll", this.scrollHandler);
  // }

  async handleFileChange(e: any) {
    const video = e.target.files[0];

    if (video) {
      this.learningMaterial.video = video;
      this.videoSrc = await toBase64(video);
      this.localVideo = true;
    }
  }

  addSingleQuestion() {
    this.learningMaterial.questions.push({
      ...JSON.parse(JSON.stringify(this.initialSingleQuestion)),
      type: questionType.single
    });
  }

  addMultipleQuestion() {
    this.learningMaterial.questions.push({
      ...JSON.parse(JSON.stringify(this.initialSingleQuestion)),
      type: questionType.multiple
    });
  }

  addOpenQuestion() {
    this.learningMaterial.questions.push({
      ...JSON.parse(JSON.stringify(this.initialOpenQuestion)),
      type: questionType.open
    });
  }

  addCorrespondenceQuestion() {
    this.learningMaterial.questions.push({
      ...JSON.parse(JSON.stringify(this.initialCorrespondenceQuestion)),
      type: questionType.correspondence
    });
  }

  async validateBlock() {
    const value = await (this.$refs.questionsForm as Vue & {
      validate: () => boolean;
    }).validate();

    return value;
  }

  handleFormatGrade() {
    this.learningMaterial.maxGrade = Number(
      this.learningMaterial.maxGrade.toString().replace(/^0+/, "")
    );
  }

  async deleteVideo() {
    const res = await this.$dialog.error({
      text: "Do you really want to delete video?",
      title: "Warning",
      persistent: false,
      actions: {
        No: { text: "No" },
        Yes: {
          color: "red",
          text: "Yes I do"
        }
      }
    });

    if (res === "Yes") {
      if (!this.localVideo) {
        try {
          this.deletingVideo = true;
          await OnlineCourses.deleteVideo(this.learningMaterial.id);
          this.$success("Video deleted successfully");
        } catch (error) {
          const err = error as any;
          this.$error({
            err:
              err?.result?.message ||
              "Something went wrong or fvideo was not found"
          });
          this.deletingVideo = false;
          return;
        }
      }
      this.learningMaterial.video = "";
      this.videoSrc = "";
      this.localVideo = false;
      this.deletingVideo = false;
    }
  }

  async removeQuestion(qind: number, questionId: number | undefined) {
    const res = await this.$dialog.error({
      text: "Do you really want to delete item?",
      title: "Warning",
      persistent: false,
      actions: {
        No: { text: "No" },
        Yes: {
          color: "red",
          text: "Yes I do"
        }
      }
    });

    if (res === "Yes") {
      if (questionId) this.learningMaterial.deleteQuestions.push(questionId);

      if (this.learningMaterial.questions.length > 1) {
        this.learningMaterial.questions = this.learningMaterial.questions.filter(
          (_, ind) => ind != qind
        );
      } else {
        this.learningMaterial.questions = [];
      }
    }
  }
}
