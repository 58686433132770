
import { OnlineCourses } from "@/api";
import { getFileType } from "@/utils/helpers";
import VLazyImage from "v-lazy-image/v2";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Component, Prop, Vue } from "vue-property-decorator";
import DeleteItemBtn from "../common/DeleteItemBtn.vue";

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    VLazyImage,
    DeleteItemBtn
  }
})
export default class SingleQuestion extends Vue {
  @Prop()
  qind!: number;
  @Prop()
  question!: any;
  @Prop()
  isMultiple!: boolean;
  @Prop({ default: false })
  blocked!: boolean;

  key = 1;
  initialAnswer: { answer: string; correct: boolean } = {
    answer: "",
    correct: false
  };
  deletingFile = false;

  getFileType = getFileType;
  addAnswer(qind: number) {
    this.$emit("addAnswer", qind);
    this.question.answers.push({
      ...JSON.parse(JSON.stringify(this.initialAnswer))
    });
  }

  handleFormatGrade() {
    this.question.grade = Number(
      this.question.grade.toString().replace(/^0+/, "")
    );
  }
  setCorrectAnswer(aind: number) {
    if (this.blocked) return;
    if (!this.isMultiple) {
      this.question.answers.forEach((answer: any) => (answer.correct = false));
      this.question.answers[aind].correct = true;
    } else {
      this.question.answers[aind].correct = !this.question.answers[aind]
        .correct;
    }
  }
  removeAnswer(aind: number) {
    if (this.question.answers[aind].correct) {
      if (aind > 0) {
        this.question.answers[aind - 1].correct = true;
      } else {
        this.question.answers[1].correct = true;
      }
    }
    this.question.answers = this.question.answers.filter(
      (_: any, ind: number) => ind != aind
    );
  }

  async deleteFile(file: any) {
    const res = await this.$dialog.error({
      text: "Do you really want to delete file?",
      title: "Warning",
      persistent: false,
      actions: {
        No: { text: "No" },
        Yes: {
          color: "red",
          text: "Yes I do"
        }
      }
    });

    if (res === "Yes") {
      try {
        this.deletingFile = true;
        await OnlineCourses.deleteFileInQuestion(file.id);
        this.question.links = this.question.links.filter(
          (link: any) => link !== file
        );
        this.key += 1;
        this.$success("File deleted successfully");
      } catch (error) {
        const err = error as any;
        this.$error({
          err:
            err?.result?.message || "Something went wrong or file was not found"
        });
      } finally {
        this.deletingFile = false;
      }
    }
  }
}
