
import { OnlineCourses } from "@/api";
import { toBase64 } from "@/utils/helpers";
import VLazyImage from "v-lazy-image/v2";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Component, Prop, Vue } from "vue-property-decorator";
import DeleteItemBtn from "../common/DeleteItemBtn.vue";

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    VLazyImage,
    DeleteItemBtn
  }
})
export default class CorrespondenceQuestion extends Vue {
  @Prop()
  qind!: number;
  @Prop()
  question!: any;
  @Prop({ default: false })
  blocked!: boolean;

  imgSrc = "";

  initialAnswer: { answer: string; correct: string } = {
    answer: "",
    correct: "-"
  };

  get variants() {
    const variantslength = this.question.answers.length;
    const res = [{ name: "-", disabled: false }];
    for (let i = 1; i <= variantslength; i++) {
      res.push({ name: `${i}`, disabled: false });
    }
    res.forEach(item => {
      if (
        this.question.answers.find(
          (answer: any) =>
            answer.correct === item.name && answer.correct !== "-"
        )
      ) {
        item["disabled"] = true;
      } else {
        item["disabled"] = false;
      }
    });
    return res;
  }

  mounted() {
    if (this.question.links && this.question.links[0])
      this.imgSrc = this.question.links[0].url;
  }

  addAnswer(qind: number) {
    this.$emit("addAnswer", qind);
    this.question.answers.push({
      ...JSON.parse(JSON.stringify(this.initialAnswer))
    });
  }

  handleFormatGrade() {
    this.question.grade = Number(
      this.question.grade.toString().replace(/^0+/, "")
    );
  }
  // setCorrectAnswer(aind: number, val: string) {
  // this.question.answers[aind].correct = val;
  // this.question.answers.
  // }
  removeAnswer(aind: number) {
    if (this.question.answers[aind].correct) {
      if (aind > 0) {
        this.question.answers[aind - 1].correct = true;
      } else {
        this.question.answers[1].correct = true;
      }
    }
    this.question.answers = this.question.answers.filter(
      (_: any, ind: number) => ind != aind
    );
  }

  handleImageChoose() {
    const img: any = this.$refs.imgInput;
    img.click();
  }

  async handleFileChange(e: any) {
    const img = e.target.files[0];

    if (img) {
      this.question.files = [img];
      this.imgSrc = await toBase64(img);
    }
  }
  async deleteFile(imgUrl: string) {
    const res = await this.$dialog.error({
      text: "Do you really want to delete file?",
      title: "Warning",
      persistent: false,
      actions: {
        No: { text: "No" },
        Yes: {
          color: "red",
          text: "Yes I do"
        }
      }
    });

    if (res === "Yes") {
      try {
        if (this.question?.links && this.question?.links[0]) {
          const file = this.question.links[0];

          if (file && file.id) {
            await OnlineCourses.deleteFileInQuestion(file.id);
            this.question.links = this.question.links.filter(
              (link: any) => link.url !== imgUrl
            );
          }
        } else {
          this.question.files = [];
        }
        this.imgSrc = "";
        this.$success("File deleted successfully");
      } catch (error) {
        const err = error as any;
        this.$error({
          err:
            err?.result?.message || "Something went wrong or file was not found"
        });
      }
    }
  }
}
