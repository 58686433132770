
import { OnlineCourses } from "@/api";
import { EQuestionType } from "@/store/question.types";
import { questionType } from "@/utils/constants";
import { formatGrade, transformAnswers } from "@/utils/helpers";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Component, Prop, Vue } from "vue-property-decorator";
import { deleteConfirmation } from "../../utils/helpers";
import CorrespondenceQuestion from "./CorrespondenceQuestion.vue";
import SingleQuestion from "./SingleQuestion.vue";

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    SingleQuestion,
    CorrespondenceQuestion
  }
})
export default class SummativeTest extends Vue {
  @Prop()
  courseId!: number;
  @Prop()
  tab!: number;
  @Prop()
  hasEssayTest!: boolean;
  @Prop()
  isUSQualificationType!: boolean;
  @Prop({ default: false })
  blocked!: boolean;

  summativeMaxGrade = 0;
  initialAnswer: { answer: string; correct: boolean } = {
    answer: "",
    correct: false
  };
  initialSingleQuestion = {
    text: "",
    grade: 0,
    note: "",
    answers: [
      { answer: "", correct: true },
      { answer: "", correct: false }
    ],
    files: null
  };
  initialCorrespondenceQuestion = {
    text: "",
    grade: 0,
    note: "",
    answers: [
      { answer: "", correct: "-" },
      { answer: "", correct: "-" }
    ],
    files: null
  };

  questions: {
    id: number;
    questionId: number;
    text: string;
    grade: number;
    type: EQuestionType;
    note: string;
    files: any[];
    links: any[];
    answers: { answer: string; correct: boolean }[];
  }[] = [];

  deleteQuestions: number[] = [];
  saving = false;
  formatGrade = formatGrade;
  deleteConfirmation = deleteConfirmation;

  async mounted() {
    await this.fetchTest();
  }

  async fetchTest() {
    const response = await OnlineCourses.getSummativeTests(this.courseId);

    this.summativeMaxGrade = response.maxGrade;
    this.questions = (response.questions || []).map((question: any) => {
      const answers = transformAnswers(question);
      return { ...question, answers };
    });

    this.questions.forEach(question => {
      if (question.files && question.files.length) {
        question.links = question.files;
        question.files = [];
      }
    });
  }

  async save() {
    if (this.summativeMaxGrade < 1) {
      this.$error({ error: `Summative Max Grade must be more then 0` });
      return;
    }
    const blocks = (this.$refs.testsForm as Vue & any) || [];

    for (let i = 0; i < blocks.length; i++) {
      const valid = await blocks[i].validate();

      if (!valid) {
        this.$error({ error: `Block ${i + 1} is invalid.` });
        blocks[i].setErrors("Invalid");
        return;
      }
    }

    let haveAllRightAnswer = true;

    this.questions.forEach((question, qind) => {
      const validQuestion = question.answers.some(answer => answer.correct);
      if (!validQuestion) {
        haveAllRightAnswer = false;
        this.$error({ error: `Block ${qind + 1} is invalid.` });
        blocks[qind].setErrors("Invalid");
      }
    });

    if (!haveAllRightAnswer) return;

    const testsGrades = this.questions.reduce((acc: number, cur: any) => {
      return acc + +cur.grade;
    }, 0);

    if (testsGrades != +this.summativeMaxGrade) {
      this.$error({
        error: `Questions total grade must be ${this.summativeMaxGrade}`
      });
      return;
    }

    try {
      const questions: any = this.questions.map((question: any) => {
        const answers: string[] = [];
        const variants: string[] = [];

        question.answers.forEach((answer: any) => {
          variants.push(answer.answer);
          if (question.type.name === questionType.correspondence.name) {
            if (answer.correct && answer.correct !== "-") {
              answers.push(`${answer.correct}${answer.answer}`);
            }
          } else {
            if (answer.correct) {
              answers.push(answer.answer);
            }
          }
        });

        const maxAnswers = answers.length;

        return {
          ...question,
          maxAnswers,
          variants,
          answers
        };
      });

      const formData = new FormData();

      formData.append(`summative_max_grade`, String(this.summativeMaxGrade));

      questions.forEach((question: any, ind: number) => {
        formData.append(`questions[${ind}][text]`, question.text);
        formData.append(`questions[${ind}][grade]`, question.grade);
        formData.append(`questions[${ind}][note]`, question.note || "");
        formData.append(`questions[${ind}][type_id]`, question.type.id);
        formData.append(`questions[${ind}][max_answers]`, question.maxAnswers);

        if (question.id) {
          formData.append(`questions[${ind}][id]`, question.id);
        }
        if (question.questionId) {
          formData.append(`questions[${ind}][id]`, question.questionId);
        }
        question.variants.forEach((variant: string, aaind: number) => {
          formData.append(`questions[${ind}][variants][${aaind}]`, variant);
        });
        question.answers.forEach((answer: any, vind: number) => {
          formData.append(`questions[${ind}][answers][${vind}]`, answer);
        });

        if (question.files) {
          question.files.forEach((file: any, find: number) => {
            if (question.id) {
              if (file.originalName) {
                formData.append(
                  `questions[${ind}][files][${find}][original_name]`,
                  file.originalName
                );
              } else {
                formData.append(`questions[${ind}][files][${find}]`, file);
              }
            } else {
              formData.append(`questions[${ind}][files][${find}]`, file);
            }
          });
        }
      });
      this.deleteQuestions.forEach((question: any, ind: number) => {
        formData.append(`delete_questions[${ind}]`, question);
      });

      this.saving = true;
      await OnlineCourses.createSummativeTest(this.courseId, formData);

      this.$success("<strong>Success!</strong> Questions have been saved!");

      this.$emit("saved");
      // if (this.hasEssayTest) {
      //   return;
      // }
      // if (
      //   this.$route.name === "CreateOnlineCourse" ||
      //   this.$route.name === "EditOnlineCourse"
      // ) {
      //   if (this.tab === 2 && !this.saving) {
      // this.$router.push({ name: "OnlineCoursesList" });
      // } else {
      // this.$emit("saved");
      // }
      // }
      this.fetchTest();
      // if (this.$route.name === "EditOnlineCourse") {
      //   this.fetchTest();
      // }
    } catch (e) {
      const err = e as any;
      await this.$error(err.errors);
    } finally {
      this.saving = false;
    }
  }

  async removeQuestion(qind: number, questionId: number) {
    const res = await this.deleteConfirmation(
      "Do you really want to delete item?"
    );

    if (res === "Yes") {
      // this.saving = true;

      if (questionId) this.deleteQuestions.push(questionId);

      if (this.questions.length > 1) {
        this.questions = this.questions.filter((_, ind) => ind != qind);
      } else {
        this.questions = [];
      }
      // await this.save();
    }
  }

  addSingleQuestion() {
    this.questions.push({
      ...JSON.parse(JSON.stringify(this.initialSingleQuestion)),
      type: questionType.single
    });
  }
  addMultipleQuestion() {
    this.questions.push({
      ...JSON.parse(JSON.stringify(this.initialSingleQuestion)),
      type: questionType.multiple
    });
  }
  addCorrespondenceQuestion() {
    this.questions.push({
      ...JSON.parse(JSON.stringify(this.initialCorrespondenceQuestion)),
      type: questionType.correspondence
    });
  }
  async deleteTest() {
    const res = await this.deleteConfirmation(
      "Do you really want to delete Summative Assessment?"
    );
    if (res === "Yes") {
      try {
        if (this.summativeMaxGrade) {
          await OnlineCourses.deleteSummativeTest(this.courseId);
        }
        this.$success(
          `<strong>Success!</strong> Summative Assessment has been deleted!`
        );
        this.$emit("onDeleteSummativeTest");
      } catch (e) {
        const err = e as any;
        await this.$error(err.errors || { err: err.result.message });
      }
    }
  }
}
