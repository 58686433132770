
import { Courses, OnlineCourses, Services } from "@/api";
import { Course, OnlineCourse, ServiceList } from "@/api/types";
import InstructorsList from "@/components/instructors/InstructorsList.vue";
import { toBase64 } from "@/utils/helpers";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

type VideoType = "intro" | "final";

@Component({
  components: {
    InstructorsList,
    ValidationProvider,
    ValidationObserver
  }
})
export default class GeneralInfo extends Vue {
  @Prop({ required: false })
  info!: any;
  @Prop({ required: false })
  courseId!: any;
  @Prop()
  isQualificationType!: boolean;
  @Prop({ default: false })
  blocked!: boolean;

  tableHeader = {
    title: "Assign Instructor",
    icon: "mdi-account"
  };

  selectedInstructors: Array<number> = [];
  initialCourse: OnlineCourse = {
    categoryId: 0,
    saqaUnitStandard: "",
    qualificationTitle: "",
    nqfLevel: "",
    credits: "",
    dol: "",
    courseTypeId: "",
    durationDays: 1,
    instructors: [],
    description: "",
    name: "",
    price: 0,
    qualificationType: "",
    providerCode: "",
    providerEtqaId: "",
    assessments: []
  };

  course = { ...this.initialCourse };
  courses: Course[] = [];
  selectedCourse: Course | null = null;

  categories = [];
  types = [];
  pdfManual: Blob[] | null = null;

  assessmentsArray = [
    { title: "" },
    { title: "" },
    { title: "" },
    { title: "" },
    { title: "" },
    { title: "" },
    { title: "" },
    { title: "" },
    { title: "" },
    { title: "" },
    { title: "" },
    { title: "" }
  ];

  list: ServiceList | null = null;
  loading = false;
  processing = false;
  tabDisabled = false;
  pdfForDeletion: string[] = [];

  introVideo: string | Blob | null = null;
  finalVideo: string | Blob | null = null;
  introVideoSrc = "";
  finalVideoSrc = "";
  localIntroVideo = false;
  localFinalVideo = false;
  deletingVideo: VideoType | null = null;
  categoryId = this.initialCourse.categoryId;

  get isQualification() {
    return this.course?.qualificationType === "Qualification";
  }
  get isSkillProgramme() {
    return this.categoryId == 9;
  }
  get isOccupationalCertificate() {
    return this.categoryId == 8;
  }
  get outcomeLabel() {
    if (this.isQualification) return "Exit Level Outcome";
    if (this.isOccupationalCertificate) return "Expert Level Outcomes";
    if (this.isSkillProgramme) return "Exit Skills Outcome";
    return "Specific Outcome";
  }

  async created() {
    this.loading = true;
    try {
      let courses;
      [this.categories, this.types, this.list, courses] = await Promise.all([
        Courses.categories(),
        Courses.types(),
        Services.list(),
        Courses.list(1, "", 999)
      ]);

      this.courses = courses.data || [];
      this.selectedCourse =
        this.courses.find(course => course.name === this.course.name) || null;
      if (this.course.intro) {
        this.introVideoSrc = this.course.intro.url;
        this.introVideo = this.course.intro.originalName;
      }
      if (this.course.outro) {
        this.finalVideoSrc = this.course.outro.url;
        this.finalVideo = this.course.outro.originalName;
      }
    } catch (e) {
      const err = e as any;
      await this.$error(err.errors);
    } finally {
      this.loading = false;
    }
  }

  @Watch("course.qualificationType")
  handleUsQualificationSelect(val: string) {
    this.$emit("update:isQualificationType", val);
  }

  @Watch("info")
  setCourse() {
    if (Object.keys(this.info).length) {
      this.course = { ...this.info };

      this.course.durationDays = this.course.duration?.day;
      this.selectedInstructors = this.course.instructors?.map(i => i.id) || [];

      this.course.categoryId = this.course.category?.id;
      this.categoryId = this.course.category?.id;

      this.course.courseTypeId = this.course.type?.id;
      this.assessmentsArray = [...this.info.assessments];
      this.fillAssessments();

      if (this.course.description === "null") this.course.description = "";
      if (this.course.dol === "null") this.course.dol = "";
    }
    //  delete this.course.blocks;
  }
  categoryIdSelect(id: number) {
    this.categoryId = id;
  }
  requiredRule(v: any) {
    return !!v || "Course is required";
  }

  selectCourse(course: any) {
    delete course.id;
    this.course = { ...this.initialCourse, ...course };

    this.course.durationDays = this.course.duration?.id;
    this.course.categoryId = course.category?.id;
    this.categoryId = course.category?.id;
    this.assessmentsArray = [...course.assessments];

    this.fillAssessments();

    this.pdfManual = null;
    this.selectedInstructors = [];
  }

  fillAssessments() {
    for (let i = 0; i < 12; i++) {
      if (!this.assessmentsArray[i]) {
        this.assessmentsArray.push({ title: "" });
      }
    }
  }

  async onSave() {
    (this.$refs.form as Vue & {
      click: () => void;
    }).click();
  }

  async save() {
    try {
      if (this.selectedInstructors.length === 0) {
        await this.$error({ error: "Please choose an Instructor " });
        return;
      }
      if (!this.course.price || this.course.price === 0) {
        await this.$error({ error: "Price is required" });
        return;
      }

      this.processing = true;
      const formData = new FormData();

      // has pdfs to upload
      if (this.pdfManual?.length) {
        this.pdfManual.forEach((pdf, ind) => {
          formData.append(`pdf_manual[${ind}]`, pdf);
        });
      }

      // has pdfs to delete
      if (this.pdfForDeletion.length) {
        this.pdfForDeletion.forEach((pdf, ind) => {
          formData.append(`pdf_manual_for_deletion[${ind}]`, pdf);
        });
      }

      // if has intro video
      if (this.introVideo && typeof this.introVideo !== "string") {
        formData.append("intro", this.introVideo);
      }
      // if has final video
      if (this.finalVideo && typeof this.finalVideo !== "string") {
        formData.append("outro", this.finalVideo);
      }

      formData.append("name", this.course.name || "");
      formData.append("course_duration_id", String(this.course.durationDays));
      formData.append("price", String(this.course.price));
      formData.append("description", this.course.description || "");
      formData.append("category_id", String(this.course.categoryId));
      formData.append("course_type_id", String(this.course.courseTypeId));
      formData.append("nqf_level", String(this.course.nqfLevel));
      formData.append(
        "saqa_unit_standard",
        String(this.course.saqaUnitStandard)
      );
      formData.append(
        "qualification_title",
        String(this.course.qualificationTitle)
      );
      formData.append("credits", String(this.course.credits));

      formData.append("dol", String(this.course.dol));
      formData.append(
        "qualification_type",
        String(this.course.qualificationType)
      );
      formData.append("provider_code", this.course.providerCode);
      formData.append("provider_etqa_id", this.course.providerEtqaId);

      this.selectedInstructors.forEach(instructor => {
        formData.append("instructors[]", String(instructor));
      });

      this.assessmentsArray
        .filter(assessment => assessment.title)
        .forEach(assessment => {
          formData.append("assessments[][title]", assessment.title);
        });

      if (this.courseId) {
        formData.append("_method", "PUT");
        await OnlineCourses.update(String(this.courseId), formData);
        this.$emit("saved");
        this.$success("<strong>Success!</strong> Course has been updated!");
      } else {
        const res = await OnlineCourses.create(formData);
        this.$emit("saved", {
          id: res.id
        });
        this.$success("<strong>Success!</strong> Course has been created!");
        // this.tabDisabled = true;
      }
    } catch (e) {
      const err = e as any;
      if (err && err.errors) {
        (this.$refs.courseForm as Vue & {
          setErrors: (errors: any) => void;
        }).setErrors(err.errors);
        this.$error(err.errors);
      } else {
        this.$error(
          err?.result?.message
            ? { err: err?.result?.message }
            : { err: "Something went wrong" }
        );
      }
    } finally {
      this.processing = false;
    }
  }
  handlePdfChange(e: any) {
    this.pdfManual = e.target.files[0];
  }
  handleFormatGrade(
    item: any,
    target: "formativeMaxGrade" | "summativeMaxGrade"
  ) {
    const res = Number(item.toString().replace(/^0+/, ""));
    this.course[target] = res;
  }
  async handleShowPdf() {
    if (this.courseId) {
      const pdf = await OnlineCourses.getPdf(this.courseId);

      const file = new Blob([pdf], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, "_blank");
    }
  }

  async deletePdf(id: string) {
    const res = await this.$dialog.error({
      text: "Do you realy want to delete file?",
      title: "Warning",
      persistent: false,
      actions: {
        No: { text: "No" },
        Yes: {
          color: "red",
          text: "Yes I do"
        }
      }
    });
    if (res === "Yes") {
      this.course.pdfManual = this.course.pdfManual?.filter(
        manual => manual.id !== id
      );
      this.pdfForDeletion.push(id);
      this.onSave();
    }
  }

  handleVideoChoose(type: VideoType) {
    if (this.blocked) return;
    let video: any;
    if (type === "intro") {
      video = this.$refs.introVideoInput;
    }
    if (type === "final") {
      video = this.$refs.finalVideoInput;
    }
    if (video && !this.tabDisabled) video.click();
  }

  async handleFileChange(e: any, type: VideoType) {
    const video = e.target.files[0];

    if (video) {
      if (type === "intro") {
        this.introVideo = video;
        this.introVideoSrc = await toBase64(video);
        this.localIntroVideo = true;
      }
      if (type === "final") {
        this.finalVideo = video;
        this.finalVideoSrc = await toBase64(video);
        this.localFinalVideo = true;
      }
    }
  }

  async deleteVideo(type: VideoType) {
    const res = await this.$dialog.error({
      text: "Do you really want to delete video?",
      title: "Warning",
      persistent: false,
      actions: {
        No: { text: "No" },
        Yes: {
          color: "red",
          text: "Yes I do"
        }
      }
    });

    if (res === "Yes") {
      try {
        if (type === "intro" && !this.localIntroVideo) {
          this.deletingVideo = type;
          await OnlineCourses.deleteIntroVideo(this.course.id);
        }
        if (type === "final" && !this.localFinalVideo) {
          this.deletingVideo = type;
          await OnlineCourses.deleteFinalVideo(this.course.id);
        }
        this.$success("Video deleted successfully");
      } catch (error) {
        const err = error as any;
        this.$error({
          err:
            err?.result?.message ||
            "Something went wrong or fvideo was not found"
        });
        this.deletingVideo = null;
        return;
      }
      if (type === "intro") {
        this.introVideo = "";
        this.introVideoSrc = "";
        this.localIntroVideo = false;
      }
      if (type === "final") {
        this.finalVideo = "";
        this.finalVideoSrc = "";
        this.localFinalVideo = false;
      }
      this.deletingVideo = null;
    }
  }
}
