
import { OnlineCourses } from "@/api";
import { EssayItemType, EssayType } from "@/api/types";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Component, Prop, Vue } from "vue-property-decorator";
import { deleteConfirmation } from "../../utils/helpers";

@Component({
  components: {
    ValidationObserver,
    ValidationProvider
  }
})
export default class EssayTab extends Vue {
  @Prop()
  courseId!: string;
  @Prop()
  tab!: number;
  @Prop()
  essayTabName!: string;
  @Prop({ default: false })
  blocked!: boolean;

  loading = false;
  essayId: string | null = null;
  initialQuestion: EssayItemType = {
    text: "",
    note: "",
    grade: 0
  };
  essayMaxGrade = 0;
  questions: EssayItemType[] = [];
  deleteQuestions: number[] = [];

  deleteConfirmation = deleteConfirmation;

  async mounted() {
    await this.getEssayTest();
  }

  async getEssayTest() {
    try {
      if (this.courseId) {
        const essay = await OnlineCourses.getEssayTest(this.courseId);

        if (essay.id) {
          const questions = essay.questions?.map((item: EssayItemType) => ({
            text: item.text,
            note: item.note || "",
            grade: item.grade,
            questionId: item.questionId
          }));
          this.essayId = essay.id;
          this.questions = questions;
          this.essayMaxGrade = essay.maxGrade;
        } else {
          this.questions.push({
            ...JSON.parse(JSON.stringify(this.initialQuestion))
          });
        }
      }
    } catch (error) {
      //
    }
  }

  async save() {
    const valid = await (this.$refs.essayForm as Vue & {
      validate: () => boolean;
    }).validate();

    //  check for zero grades
    const hasZeroGrade = this.questions.reduce((acc, cur) => {
      return acc || cur.grade == 0;
    }, false);
    if (!this.essayMaxGrade || hasZeroGrade) {
      await this.$error({ error: "Grade has to be more 0" });
      return;
    }

    // check the sum of the question grades to be equal to the essay max grade
    const gradesSum = this.questions.reduce((acc, cur) => {
      return (acc += cur.grade);
    }, 0);

    if (gradesSum !== this.essayMaxGrade) {
      await this.$error({
        error:
          "The sum of the questions grades must be equal to the Essay Max Grade"
      });
      return;
    }

    if (valid) {
      this.loading = true;

      const data: EssayType = {
        id: this.essayId,
        questions: [...this.questions],
        deleteQuestions: [...this.deleteQuestions],
        essayMaxGrade: this.essayMaxGrade
      };

      try {
        await OnlineCourses.createEssayTest(this.courseId, data);
        this.loading = false;

        await this.$success("Essay has been created");
        if (
          (this.$route.name === "CreateOnlineCourse" ||
            this.$route.name === "EditOnlineCourse") &&
          this.tab === 3
        )
          this.$router.push({ name: "OnlineCoursesList" });
      } catch (error) {
        const err = error as any;
        this.$error(err.errors || { error: err.result.message });
        (this.$refs.essayForm as Vue & {
          setErrors: (errors: any) => void;
        }).setErrors(err.errors);
      } finally {
        this.loading = false;
      }
    }
  }

  async deleteEssay() {
    const res = await this.$dialog.error({
      text: "Do you really want to delete essay assessment?",
      title: "Warning",
      persistent: false,
      actions: {
        No: { text: "No" },
        Yes: {
          color: "red",
          text: "Yes I do"
        }
      }
    });
    if (res === "Yes") {
      if (this.essayId) {
        this.loading = true;
        try {
          await OnlineCourses.deleteEssay(this.courseId);
          this.loading = false;
          await this.getEssayTest();

          await this.$success("Essay has been deleted");
        } catch (error) {
          const err = error as any;
          this.$error(
            err.errors || {
              error: err.message || "Something went wrong "
            }
          );
        } finally {
          this.loading = false;
        }
      } else {
        this.essayId = null;
        this.essayMaxGrade = 0;
        this.questions = [];
        this.deleteQuestions = [];
        this.essayMaxGrade = 0;
      }
      this.$emit("deleteEssay");
    }
  }

  addQuestion() {
    this.questions.push({
      ...JSON.parse(JSON.stringify(this.initialQuestion))
    });
  }

  async removeQuestion(question: EssayItemType) {
    const comfirm = await this.deleteConfirmation();

    if (comfirm === "Yes") {
      if (question.questionId) this.deleteQuestions.push(question.questionId);

      if (this.questions.length > 1) {
        this.questions = this.questions.filter(item => item !== question);
      } else {
        this.questions = [];
      }
    }
  }
  formatGrade(item: any): number {
    return Number(item.toString().replace(/^0+/, ""));
  }
}
