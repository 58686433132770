
import { OnlineCourses } from "@/api";
import { PracticalTaskType } from "@/api/types";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Component, Prop, Vue } from "vue-property-decorator";
import { deleteConfirmation } from "../../utils/helpers";

type ModuleItem = {
  id?: number;
  typeId?: number;
  title: string;
  credits: number | string;
  type: {
    id: number;
    name: "Practical Skills" | "Work Experience";
  };
};

@Component({
  components: {
    ValidationObserver,
    ValidationProvider
  }
})
export default class PracticalTest extends Vue {
  @Prop()
  courseId!: string;
  @Prop()
  tab!: number;
  @Prop({ default: false })
  blocked!: boolean;

  loading = false;
  practicalId: string | null = null;
  initialTask: PracticalTaskType = {
    task: "",
    grade: 0,
    files: [],
    documents: []
  };
  practicalMaxGrade = 0;
  tasks: PracticalTaskType[] = [];
  deleteTasks: number[] = [];
  deleteFiles: string[] = [];
  practicalSkills: ModuleItem[] = [];
  workExperiences: ModuleItem[] = [];
  initialModule = { title: "", credits: "" };
  deletedModules: number[] = [];

  deleteConfirmation = deleteConfirmation;

  async mounted() {
    await this.getPracticalTest();
  }

  async getPracticalTest() {
    try {
      if (this.courseId) {
        const practical = await OnlineCourses.getPracticalTest(
          this.courseId
        ).catch(() => {
          //
        });

        if (practical?.id) {
          const tasks = practical.tasks?.map((item: PracticalTaskType) => ({
            id: item.id,
            task: item.task,
            files: item.files,
            grade: item.grade,
            documents: []
          }));
          this.practicalId = practical.id;
          this.tasks = tasks;
          this.practicalMaxGrade = practical.maxGrade;

          if (practical.modules && practical.modules.length > 0) {
            this.practicalSkills = [];
            this.workExperiences = [];
            practical.modules.forEach((module: any) => {
              if (module.type?.name === "Practical Skills") {
                this.practicalSkills.push(module);
              }
              if (module.type?.name === "Work Experience") {
                this.workExperiences.push(module);
              }
            });
          } else {
            this.setInitialModules();
          }
        } else {
          this.tasks.push({
            ...JSON.parse(JSON.stringify(this.initialTask))
          });

          this.setInitialModules();
        }
      }
    } catch (error) {
      //
    }
  }

  setInitialModules() {
    this.practicalSkills.push(JSON.parse(JSON.stringify(this.initialModule)));
    this.workExperiences.push(JSON.parse(JSON.stringify(this.initialModule)));
  }

  async save() {
    const valid = await (this.$refs.practicalForm as Vue & {
      validate: () => boolean;
    }).validate();

    //  check for zero grades
    const hasZeroGrade = this.tasks.reduce((acc, cur) => {
      return acc || cur.grade == 0;
    }, false);
    if (!this.practicalMaxGrade || hasZeroGrade) {
      await this.$error({ error: "Grade has to be more 0" });
      return;
    }

    // check the sum of the question grades to be equal to the essay max grade
    const gradesSum = this.tasks.reduce((acc, cur) => {
      return (acc += cur.grade);
    }, 0);

    if (gradesSum !== this.practicalMaxGrade) {
      await this.$error({
        error:
          "The sum of the questions grades must be equal to the Practical Assessment Max Grade"
      });
      return;
    }

    this.workExperiences = this.workExperiences.filter(
      item => !!item.credits || !!item.title
    );
    this.practicalSkills = this.practicalSkills.filter(
      item => !!item.credits || !!item.title
    );

    if (this.workExperiences.length < 1) {
      this.$error({
        error: "Add Work Experience"
      });
      return;
    }
    if (this.practicalSkills.length < 1) {
      this.$error({
        error: "Add Practical Skills"
      });
      return;
    }

    if (valid) {
      this.loading = true;

      const formData = new FormData();
      if (this.practicalId) {
        formData.append("id", String(this.practicalId));
      }

      const practicalModules = this.practicalSkills.map(module => ({
        ...module,
        typeId: 1
      }));
      const workExperienceModules = this.workExperiences.map(module => ({
        ...module,
        typeId: 2
      }));
      const modules = [...practicalModules, ...workExperienceModules];

      modules.map((module, ind) => {
        if (module.id) {
          formData.append(`modules[${ind}][id]`, String(module.id));
        }
        formData.append(`modules[${ind}][type_id]`, String(module.typeId));
        formData.append(`modules[${ind}][title]`, module.title);
        formData.append(`modules[${ind}][credits]`, String(module.credits));
      });

      formData.append("practical_max_grade", String(this.practicalMaxGrade));

      this.tasks.forEach((task, ind) => {
        if (task.id) {
          formData.append(`tasks[${ind}][id]`, String(task.id));
        }
        formData.append(`tasks[${ind}][task]`, String(task.task));
        formData.append(`tasks[${ind}][grade]`, String(task.grade));
        task.documents.forEach((document, find) => {
          formData.append(`tasks[${ind}][files][${find}]`, document);
        });
      });

      // has tasks to delete
      if (this.deleteTasks.length) {
        this.deleteTasks.forEach((taskId, ind) => {
          formData.append(`delete_tasks[${ind}]`, String(taskId));
        });
      }
      // has files to delete
      if (this.deleteFiles.length) {
        this.deleteFiles.forEach((fileId, ind) => {
          formData.append(`delete_files[${ind}]`, String(fileId));
        });
      }

      // has modules to delete
      if (this.deletedModules.length) {
        this.deletedModules.forEach((moduleId, ind) => {
          formData.append(`delete_modules[${ind}]`, String(moduleId));
        });
      }

      try {
        await OnlineCourses.createPracticalTest(this.courseId, formData);
        this.loading = false;

        this.$success("Practical Tesk has been saved");
        await this.getPracticalTest();
        // if (
        // this.$route.name === "CreateOnlineCourse"
        //  ||
        //   this.$route.name === "EditOnlineCourse") &&
        // this.tab === 3
        // )
        // this.$router.push({ name: "OnlineCoursesList" });
      } catch (error) {
        const err = error as any;
        this.$error(err.errors || { error: err.result.message });
        (this.$refs.practicalForm as Vue & {
          setErrors: (errors: any) => void;
        }).setErrors(err.errors);
      } finally {
        this.loading = false;
      }
    }
  }

  async deletePracticalTest() {
    const res = await this.deleteConfirmation(
      "Do you really want to delete assessment?"
    );
    if (res === "Yes") {
      if (this.practicalId) {
        this.loading = true;
        try {
          await OnlineCourses.deletePracticalTest(this.courseId);
          this.loading = false;

          await this.$success("Practical Assessment has been deleted");
        } catch (error) {
          const err = error as any;
          this.$error(
            err.errors || {
              error: err.message || "Something went wrong "
            }
          );
        } finally {
          this.loading = false;
        }
      } else {
        this.practicalId = null;
        this.practicalMaxGrade = 0;
        this.tasks = [];
        this.deleteTasks = [];
        this.deleteFiles = [];
        this.deletedModules = [];
        this.practicalMaxGrade = 0;
        this.workExperiences = [];
        this.practicalSkills = [];
      }
      this.$emit("deletePracticalTest");
    }
  }

  addTask() {
    this.tasks.push({
      ...JSON.parse(JSON.stringify(this.initialTask))
    });
  }

  async removeTask(task: PracticalTaskType) {
    const comfirm = await this.deleteConfirmation();

    if (comfirm === "Yes") {
      if (task.id) this.deleteTasks.push(task.id);

      if (this.tasks.length > 1) {
        this.tasks = this.tasks.filter(item => item !== task);
      } else {
        this.tasks = [{ ...JSON.parse(JSON.stringify(this.initialTask)) }];
      }
    }
  }

  async deleteFile(id: string, task: PracticalTaskType) {
    if (this.blocked) return;
    const confirm = await this.deleteConfirmation(
      "Do you really want to delete file?"
    );

    if (confirm && id) {
      task.files = [...task.files.filter(file => file.id !== id)];
      this.deleteFiles.push(id);
    }
  }

  formatGrade(item: any): number {
    return Number(item.toString().replace(/^0+/, ""));
  }

  addModule(module: ModuleItem[]) {
    module.push({ ...JSON.parse(JSON.stringify(this.initialModule)) });
  }
  removeItemFromModule(module: string, itemToDelete: ModuleItem) {
    if (itemToDelete?.id) {
      this.deletedModules.push(itemToDelete.id);
    }
    if (module === "skills") {
      this.practicalSkills = this.practicalSkills.filter(
        item => item !== itemToDelete
      );

      if (this.practicalSkills.length === 0) {
        this.practicalSkills.push({
          ...JSON.parse(JSON.stringify(this.initialModule))
        });
      }
    }

    if (module === "experience") {
      this.workExperiences = this.workExperiences.filter(
        item => item !== itemToDelete
      );

      if (this.workExperiences.length === 0) {
        this.workExperiences.push({
          ...JSON.parse(JSON.stringify(this.initialModule))
        });
      }
    }
  }
}
