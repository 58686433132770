
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {}
})
export default class DeleteItemBtn extends Vue {
  @Prop({ default: false })
  absolute!: boolean;
  @Prop({ default: 32 })
  size!: number;

  get styles(): any {
    return {
      position: `${this.absolute ? "absolute" : "static"}`,
      "z-index": 100,
      width: `${this.size}px`,
      height: `${this.size}px`,
      top: "30px",
      right: "20px"
    };
  }
}
