import { render, staticRenderFns } from "./SummativeTab.vue?vue&type=template&id=e58ac92c&scoped=true&"
import script from "./SummativeTab.vue?vue&type=script&lang=ts&"
export * from "./SummativeTab.vue?vue&type=script&lang=ts&"
import style0 from "./SummativeTab.vue?vue&type=style&index=0&id=e58ac92c&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e58ac92c",
  null
  
)

export default component.exports