
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Component, Prop, Vue } from "vue-property-decorator";
import DeleteItemBtn from "../common/DeleteItemBtn.vue";

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    DeleteItemBtn
  }
})
export default class SingleQuestion extends Vue {
  @Prop()
  qind!: number;
  @Prop()
  question!: any;

  handleFormatGrade() {
    this.question.grade = Number(
      this.question.grade.toString().replace(/^0+/, "")
    );
  }
}
